import React, { createContext, useContext, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Divider, Grid, LinearProgress, Link, Stack, Typography,CircularProgress, Skeleton} from '@mui/material';
import moment from 'moment';
import { GoogleMap,useLoadScript,Marker } from "@react-google-maps/api";
import CustomBreadcrumb from "../../../components/customBreadcrumb";
import CustomIcon from "../../../components/customIcon";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import listingService from './../../../services/listingServices';
import { calcTarget, calculatePercent, checkLogin, convertToIndianFormat, getDocName, isInWatchlist } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import userServices from "../../../services/userServices";
import { updateWatchlist } from "../../../redux/reducers/userSlice";
import { useSnackbar } from "notistack";
import ImageGallery from "../../../components/imageGallery";
import KYCPending from "../../../components/modals/kycPending";
import AccountSetupModal from "../../../components/modals/accountSetup";
import LoginModal from "../../../components/modals/login";
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import urlShortnerServices from "../../../services/urlShortnerServices";
import distributionServices from "../../../services/distributionServices";
import { FacebookShareButton, TwitterShareButton} from 'react-share';
import { WhatsappShareButton } from 'react-share';
import { Helmet } from 'react-helmet';
import { LoadingButton } from "@mui/lab"

import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
import { color } from "style-value-types";
import ModalComponent from "../../../components/modalComponent";
import Notification from "./notification";
const PropertyContext = createContext();

export default function PropertyDetails(){

    const { propertyId } = useParams();
    const [searchParams] = useSearchParams();

    const [showLogin,setShowLogin] = useState(false);
    // const [openBuy,setBuy] = useState(false);
    const [kyc,setKyc] = useState(false);
    const [url,setUrl] = useState('');
    // const [openAmount,setOpenAmount] = useState(false);
    // const [openPayment,setOpenPayment] = useState(false);
    // const [ownerType,setOwnerType] = useState();
    // const [ammount,setAmmount] = useState(0);
    const [showSignUp,setShowSignUp] = useState(false);
    const [isLead, setIsLead] = useState(false);
    const [leadSalePricePSF, setLeadSalePricePSF] = useState(0);
    const [leadMinInvestmentAmountToken, setLeadMinInvestmentAmountToken] = useState(0);
    const [leadMinInvestmentAmountRCO, setLeadMinInvestmentAmountRCO] = useState(0);

    const [ propertyData,setPropertyData ] = useState();
    const [ratePerFrax,setRatePerFrax]= useState(0);
    const [ actualRPF,setActualRPF] = useState(0);
    const [totalCost,setTotalCost] = useState(0);
    const [totalRetailCost, setTotalRetailCost] = useState(0);
    const [noOfFrax,setNoOfFrax]= useState(0);
    const [noOfRemFrax,setNoOfRemFrax]= useState(0);

    const { state } = useLocation();
    const [showTerms,setShowTerms] = useState(false);
    const [loading,setLoading]=useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const user = useSelector(state=>state.user.data);
    
    const navigate = useNavigate();

    useEffect(()=>{
        getPropertyDetails(propertyId);
    },[propertyId]);

    useEffect(()=>{
        if(searchParams.get("referredBy"))
        {
            localStorage.setItem("redirectUrl",window.location.pathname)
        }else{
            localStorage.removeItem("redirectUrl")
        }
    },[])

    const getPropertyDetails = async(propertyId) =>{
        let res = await listingService.getListingById(propertyId);
        if(res && res.success && res.data?.length >0){
            const dynamicPrice = await getDynamicAmount();
            if(dynamicPrice && dynamicPrice?.dealFinancials?.listingPrice) {
                setIsLead(true);
                setLeadSalePricePSF(dynamicPrice?.dealFinancials?.listingPrice);
                setLeadMinInvestmentAmountToken(dynamicPrice?.dealFinancials?.minInvestmentAmountTokenization);
                setLeadMinInvestmentAmountRCO(dynamicPrice?.dealFinancials?.minInvestmentAmountRCO);
            }
        //     res.data[0].transactionDetails.totalCostOfPurchase -= ((res.data[0].transactionDetails.salePrice) + (res.data[0].transactionDetails.gstINR || 0) + (res.data[0].transactionDetails.stampDutyINR || 0));
            
        //     if(dynamicPrice && dynamicPrice?.dealFinancials?.listingPrice) {
        //         res.data[0].transactionDetails.salePrice = dynamicPrice.dealFinancials.listingPrice * res.data[0].characteristics.builtupArea;
        //     }else {
        //     res.data[0].transactionDetails.salePrice = res.data[0].transactionDetails.salePricePSF * res.data[0].characteristics.builtupArea;
        // }
        //     res.data[0].transactionDetails.gstINR = res.data[0].transactionDetails.salePrice * res.data[0].transactionDetails.gst / 100;
        //     res.data[0].transactionDetails.stampDutyINR = res.data[0].transactionDetails.salePrice * (res.data[0].transactionDetails.stampDuty / 100);
        //     res.data[0].transactionDetails.totalCostOfPurchase += ((res.data[0].transactionDetails.salePrice) + (res.data[0].transactionDetails.gstINR) + (res.data[0].transactionDetails.stampDutyINR));
        //     if(dynamicPrice?.dealFinancials?.minInvestmentAmount) res.data[0].transactionDetails.minInvestmentAmount = dynamicPrice.dealFinancials.minInvestmentAmount;

            setPropertyData(res.data[0]);
            setUrl(res.url)
        }
    }

    const getDynamicAmount = async () => {
        const res = await distributionServices.getDynamicAmount({
            mobileNumber: user?.mobileNumber,
            listingId: propertyId
        });
        if(res && res.success) return res.data;
    }

    const handleBuy = () =>{

        if(checkLogin()){
            if(user?.status === "APPROVED"){
                navigate(`/dashboard/property/${propertyData._id}/invest`,{state:{id:propertyData._id}})
            }
            else if(user?.status === "KYC_COMPLETE") setKyc(true);
            else setShowSignUp(true);
        }
        else{
            setShowLogin(true);
        }
        
    }

    const getTitle = () =>{
        if(propertyData?.propertyType === "Residential") return `${propertyData?.basicInfo?.flatNo}, Wing-${propertyData?.basicInfo?.wing}`
        if(propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail") return `${propertyData?.basicInfo?.unitNo}, Wing-${propertyData?.basicInfo?.wing}`
        if(propertyData?.propertyType === "Land") return `Plot No. ${propertyData?.basicInfo?.plotNo}`
        if(propertyData?.propertyType === "Warehouse") return `Warehouse No. ${propertyData?.basicInfo?.warehouseNo}`
        return "";
    }
    const [showPropertyURL, setPropertyURL] = useState();
    
    const calculateTotalRetailCost = (propertyData) => {
        const currentTotalCost = propertyData?.transactionDetails?.totalCostOfPurchase;
        const currentSalePrice = propertyData?.transactionDetails?.salePrice;
        const retailSalePrice = propertyData?.transactionDetails?.retailBasePricePSF * propertyData?.characteristics?.builtupArea;
        if(propertyData?.status == "LIVE") return currentTotalCost - currentSalePrice + retailSalePrice - propertyData?.transactionDetails?.gstINR + (retailSalePrice * propertyData?.transactionDetails?.gst / 100) - propertyData?.transactionDetails?.stampDutyINR + (retailSalePrice * propertyData?.transactionDetails?.stampDuty / 100);
        else return currentTotalCost - currentSalePrice + retailSalePrice;
    }

    const calculateTotalCost = (propertyData, leadSalePricePSF) => {
        if(leadSalePricePSF == 0) return calculateTotalRetailCost(propertyData);
        const currentTotalCost = propertyData?.transactionDetails?.totalCostOfPurchase;
        const currentSalePrice = propertyData?.transactionDetails?.salePrice;
        const retailSalePrice = propertyData?.transactionDetails?.retailBasePricePSF * propertyData?.characteristics?.builtupArea;
        const leadSalePrice = (leadSalePricePSF || 0) * propertyData?.characteristics?.builtupArea;
        if(propertyData?.status == "LIVE") return currentTotalCost - currentSalePrice + leadSalePrice - propertyData?.transactionDetails?.gstINR + (retailSalePrice * propertyData?.transactionDetails?.gst / 100) - propertyData?.transactionDetails?.stampDutyINR + (retailSalePrice * propertyData?.transactionDetails?.stampDuty / 100);
        else return currentTotalCost - currentSalePrice + leadSalePrice;
    }

    useEffect(()=>{

        if(propertyData){
            console.log((Number(propertyData.transactionDetails.totalCostOfPurchase))/(144 * propertyData?.characteristics?.builtupArea),"propertyData.transactionDetails.totalCostOfPurchase");
            const tempTotalCost = Math.round((Number(calculateTotalCost(propertyData, leadSalePricePSF))));
            setNoOfFrax(propertyData?.numberOfFracs);
            setNoOfRemFrax(propertyData?.remainingFracs);
            setTotalCost(Math.round(propertyData?.transactionDetails?.totalCostOfPurchase))
            setTotalRetailCost(Math.round((Number(calculateTotalRetailCost(propertyData)))));
            setRatePerFrax((propertyData?.transactionDetails?.totalCostOfPurchase)/(144 * propertyData?.characteristics?.builtupArea));
            setActualRPF((Number(calculateTotalRetailCost(propertyData)))/(144 * propertyData?.characteristics?.builtupArea))
            createShortUrl(propertyData);
        }
    },[propertyData])


                    
    const createShortUrl = async (propertyData) => {
        const res = await urlShortnerServices.createShortUrl({listingId: propertyData._id});
        if(res && res.success){
            const currentURL = window.location.origin;
            setPropertyURL(currentURL + res.data);
        }
    }

    return(
        <div>
        {/* Other components for your inner route */}
        {showPropertyURL &&
        <Helmet>
            <meta property="og:site_name" content="RealX"/>
            <meta property="og:title" content="RealX | Real Properties - Digital Holding"/>
            <meta property="og:description" content="Invest in high return properties. Starting as low as ₹5000."/>
            <meta property="og:image" content="https://platform.realx.in/assets/images/rx_preview.jpg"/>
            <meta property="og:type" content="website" />
            <meta property="og:image:type" content="image/jpg"/>
            {/* <meta property="og:image:width" content="300"/>
            <meta property="og:image:height" content="300"/> */}
            <meta property="og:url" content={showPropertyURL}/>
          {/* Additional Open Graph tags if needed */}
        </Helmet>
        }
        {propertyData?.hideProperty&&<ModalComponent open={propertyData?.hideProperty} handleClose={()=>{navigate("/dashboard")}} children={<Notification message="This property is temporarily unavailable." redirectPath="/dashboard"/>}/>}
        {propertyData?( <Box sx={{mt:checkLogin()?0:{md:15,xs:12},mx:checkLogin()?0:{xl:"100px",lg:"32px",md:"24px",xs:"16px"},mb:checkLogin()?0:{xl:5,lg:5,md:5,xs:"77px"}}}>
            <PropertyContext.Provider value={{isLead, propertyData,ratePerFrax,actualRPF,noOfFrax,totalCost,totalRetailCost,noOfRemFrax, leadSalePricePSF, leadMinInvestmentAmountToken, leadMinInvestmentAmountRCO}}>
                <LoginModal handleClose={()=>setShowLogin(false)} open={showLogin} organizationCode={searchParams.get('referredBy')} showTerms={showTerms} setShowTerms={setShowTerms}  />
                <AccountSetupModal handleClose={()=>setShowSignUp(false)} open={showSignUp}  />
                <KYCPending open={kyc} handleClose={()=>setKyc(false)} />
                <CustomBreadcrumb />
                <MainCard propertyData={propertyData}   setBuy={handleBuy} enqueueSnackbar={enqueueSnackbar} setShowLogin={setShowLogin} url={url} />
            </PropertyContext.Provider>
        </Box>):(<Box>
            <Skeleton width={800} height={100}/>
            <Box display={"flex"} marginTop={"-100px"}>
                <Skeleton width={"50%"} height={500}/>
                <Skeleton width={"50%"} height={500} sx={{marginLeft:"50px"}}/>
            </Box>
            <Box display={"flex"} marginTop={"-150px"}>
                <Skeleton width={"50%"} height={500}/>
                <Skeleton width={"50%"} height={500} sx={{marginLeft:"50px"}}/>
            </Box>
        </Box>)}
      </div>
    )
}

const MainCard = ({setBuy,propertyData,enqueueSnackbar,setShowLogin,url}) =>{

    const [propertyChar,setPropertyChar] = useState([]);
    const { watchlist } = useSelector(state=>state.user);
    const [inWatchlist,setInWatchlist] = useState(false);
    const [address,setAddress] = useState("");
    const [images,setImages] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state=>state.user.data);
    const [showSignUp,setShowSignUp] = useState(false);
    const { state } = useLocation();
    const [kyc,setKyc] = useState(false);
    const [showPropertyId, setPropertyId] = useState();

    useEffect( ()=>{
        if(propertyData){
            choosePropertyChar();
            setInWatchlist(isInWatchlist(propertyData?._id));
            setAddress(`${propertyData?.project?.location?.streetAddressOne}, ${propertyData?.project?.location?.streetAddressTwo?propertyData?.project?.location?.streetAddressTwo+" ,":"" } ${propertyData?.project?.location?.landmark}, ${propertyData?.project?.location?.city}, ${propertyData?.project?.location?.state} ${propertyData?.project?.location?.pincode}`)

            // pushing additional image for videos
            if (propertyData?.listingVideos) {
                const videoProperty = propertyData.listingImages[0].docUrl;
                propertyData.listingImages.push({docUrl: videoProperty});
            }
            setImages(propertyData.listingImages.map((e)=>url+e.docUrl));
            getPropertySharableLink(propertyData);

        }
        // eslint-disable-next-line
    },[propertyData,watchlist])


    
    const handleBuy = () =>{
        setBuy(true);

        if(checkLogin()){
            if(user?.status === "APPROVED") navigate(`/dashboard/property/${propertyData?._id}/invest`,{state:{id:state.id}})
            else if(user?.status === "KYC_COMPLETE") setKyc(true);
            else setShowSignUp(true);
        }
        else setShowLogin(true);
    }

    const getPropertySharableLink = async (propertyData)=>{
        console.log(propertyData.hideProperty);
                const id = await userServices.toggleWatchlist(propertyData?._id);
                setPropertyId(id);
    }

    const choosePropertyChar = () =>{
        let temp = [];
        if(propertyData?.propertyType === "Residential"){
            temp = [
                {
                    title:"Unit No./Flat No.",
                    value:propertyData?.basicInfo?.flatNo
                },
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Unit Type",
                    value:propertyData?.basicInfo?.unitType +" BHK"
                },
                {
                    title:"Total Builtup Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"Carpet Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.carpetArea,false)+ " sqft"
                },
            ];
        }
        else if(propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail"){
            temp = [
                {
                    title:"Unit No",
                    value:propertyData?.basicInfo?.unitNo
                },
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Total Builtup Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"Carpet Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.carpetArea,false)+ " sqft"
                },
            ];
        }
        else if(propertyData?.propertyType === "Warehouse"){
            temp = [
                {
                    title:"Warehouse/Unit No",
                    value:propertyData?.basicInfo?.warehouseNo
                },
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Total Builtup Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"Carpet Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.carpetArea,false)+ " sqft"
                },
            ];
        }
        else if(propertyData?.propertyType === "Land"){
            temp = [
                {
                    title:"Plot/Unit No",
                    value:propertyData?.basicInfo?.plotNo
                },
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"FSI",
                    // value:convertToIndianFormat(propertyData?.basicInfo?.fsi)+ " sqft"
                    value:propertyData?.basicInfo?.fsi
                },
                // {
                //     title:"SDR",
                //     value:convertToIndianFormat(propertyData?.basicInfo?.sdr)+ " sqft"
                // },
            ];
        }
        // console.log(temp);
        setPropertyChar(temp);
    }

    const getTitle = () =>{
        if(propertyData?.propertyType === "Residential") return `${propertyData?.basicInfo?.flatNo}, Wing-${propertyData?.basicInfo?.wing} in ${propertyData?.project?.projectName} - ${propertyData?.project?.location?.city}`
        if(propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail") return `${propertyData?.basicInfo?.unitNo}, Wing-${propertyData?.basicInfo?.wing} in ${propertyData?.project?.projectName} - ${propertyData?.project?.location?.city}`
        if(propertyData?.propertyType === "Land") return `Plot No. ${propertyData?.basicInfo?.plotNo} in ${propertyData?.project?.projectName} - ${propertyData?.project?.location?.city}`
        if(propertyData?.propertyType === "Warehouse") return `Warehouse No. ${propertyData?.basicInfo?.warehouseNo} in ${propertyData?.project?.projectName} - ${propertyData?.project?.location?.city}`
        return "";
    }

    const toggleWatchlist = async() =>{
        if(checkLogin()){
            const res = await userServices.toggleWatchlist(propertyData?._id);
            enqueueSnackbar(inWatchlist?"Removed from watchlist":"Added in watchlist",{variant:"success"})
            if(res && res.success){
                getMyWatchlist();
            }
        }
        else{
            setShowLogin(true);
        }
    }


    const getMyWatchlist = async()=>{
        const res = await userServices.getMyWatchlist();
        if(res && res.success && res.data?.length>0){
            console.log("updating");
            dispatch(updateWatchlist(res.data));
        }
        else{
            dispatch(updateWatchlist([]));
        }
    }



    return (
      <Grid>



        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems={{ md: "center", xs: "flex-start" }}
          spacing={2}
        >
          <Box flex={1}>
            <Stack direction={"row"} spacing={3}>
              <Link
                onClick={() =>
                  navigate("/dashboard/project", {
                    state: { id: propertyData?.project?._id },
                  })
                }
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: 12, color: "inherit", cursor: "pointer" }}
                >
                  {propertyData?.project?.projectName}
                </Typography>


              </Link>
              {/* <Link>
                                <Typography variant="subtitle1" sx={{fontSize:12,color:"inherit"}} >{propertyData?.project?.projectName}</Typography>
                            </Link> */}
            </Stack>
            <Typography variant="h3">{getTitle()}  </Typography>
          </Box>
          <Stack
            sx={{ display: { md: "flex", xs: "flex" } }}
            justifyContent={"space-between"}
            direction={"row"}
            spacing={4}
            width={{ xs: "100%", md: "auto" }}
          >
            {/* <Button onClick={toggleWatchlist} variant="outlined" >{inWatchlist?"Remove from watchlist":"Add to watchlist"}</Button> */}
            {/* <Button onClick={()=>setBuy(true)} variant="contained" >Invest Now</Button> */}

            <Box
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleWatchlist();
              }}
              sx={{
                borderRadius: "100%",
                // background:"#ffffff33 0% 0% no-repeat padding-box",
                width: 36,
                height: 36,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0.5,
                position: "relative",
                background: inWatchlist ? "white" : "inherit",
                overflow: "hidden",
                "&::before": {
                  content: inWatchlist ? null : "''",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "18px",
                  background: "inherit",
                  boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                  filter: "blur(50px) brightness(12%)",
                  zIndex: 0,
                },
              }}
            >
              {/* <CircleIcon sx={{width:"100%",height:"100%",color:"#FFF",zIndex:1,position:"relative"}} /> */}
              <CustomIcon
                name={inWatchlist ? "heart-filled" : "heart"}
                sx={{ bgcolor: inWatchlist ? "#ff3d6b" : "#FFF" }}
              />
            </Box>

              <ShareSocialMediaCard propertyData={propertyData} enqueueSnackbar={enqueueSnackbar}/>
            {/* </IconButton> */}
          </Stack>
        </Stack>
        <Grid container mt={3} rowGap={3} columnSpacing={3}>
          <Grid item md={7} xs={12}>
            <ImageGallery images={images} isSelect={propertyData?.membertype} youtubeURL={propertyData?.listingVideos} />

            <Stack
              sx={{ width: { md: "calc(100% - 12px)", xs: "100%" }, my: 5 }}
              spacing={{ md: 5, xs: 3 }}
            >
              <PropertyInfoCard propertyData={propertyData} sx={{ my: 5 }} />
            </Stack>

            <Stack
              sx={{ width: { md: "calc(100% - 12px)", xs: "100%" }, my: 5 }}
              spacing={{ md: 5, xs: 3 }}
            >
              <LegalInfoCard propertyData={propertyData} sx={{ my: 5 }} />
            </Stack>

            {/* <Stack sx={{width:{md:"calc(100% - 12px)",xs:"100%"}, my: 5}} spacing={{md:5,xs:3}}>
                            <ProjectDetailCard propertyData={propertyData} sx={{ my: 5}} url={url} />
                        </Stack> */}

            <Stack
              sx={{ width: { md: "calc(100% - 12px)", xs: "100%" }, my: 5 }}
              spacing={{ md: 5, xs: 3 }}
            >
              {propertyData?.tenancyType !== "Vacant" && (
                <TenancyDetailCard sx={{ my: 5 }} propertyData={propertyData} />
              )}
            </Stack>

            <Stack
              sx={{ width: { md: "calc(100% - 12px)", xs: "100%" }, my: 5 }}
              spacing={{ md: 5, xs: 3 }}
            >
              <LocationCard propertyData={propertyData} />
            </Stack>
          </Grid>
          <Grid item md={5} xs={12}>
            <Stack spacing={{ md: 4, xs: 3 }}>
              <HighlightCard propertyData={propertyData} />
              <InvestNowCard
                setBuy={handleBuy}
                propertyData={propertyData}
                setShowLogin={setShowLogin}
              />
              
              {propertyData?.whyInvest && 
              <WhyInvestCard propertyData={propertyData} />
              }
              <StatisticsCard setBuy={handleBuy} />

              <InvestmentAnalysisCard />
              <DocumentsCard propertyData={propertyData} url={url} />

              <Stack
                sx={{ display: { md: "none", xs: "flex" } }}
                mb={2}
                direction={"row"}
                spacing={2}
                width={{ xs: "100%", md: "auto" }}
                justifyContent={"space-between"}
              >
                <Button
                  onClick={toggleWatchlist}
                  variant="outlined"
                  sx={{ padding: 1, px: 2, height: "auto", minWidth: "auto" }}
                >
                  <CustomIcon
                    name={inWatchlist ? "heart-filled" : "heart"}
                    sx={{ bgcolor: inWatchlist ? "#ff3d6b" : "primary.main" }}
                  />
                </Button>
                    <Button
                    fullWidth
                    onClick={() => setBuy(true)}
                    variant="contained"
                    disabled={propertyData?.pauseProperty}
                  >
                    Invest Now
                  </Button>
              </Stack>

              {/* <Typography variant="body1" >There is an opportunity to purchase a 14,320 square feet commercial office space in L&T Seawoods Grand Central - India’s first and largest Transit Oriented Development - located in Navi Mumbai, for an all-in cost of Rs. 23,21,00,000 (Rs. 13,302 per sf purchase price).</Typography>
                                <Typography variant="body1" >Developed by L&T, the retail mall and one office tower has been bought by global PE fund Blackstone. Property Share is purchasing a premium sea facing office block on the 9th floor (total 11 floors) that is currently vacant but is being marketed to 3 other owners.</Typography> */}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
}

                
                

const ShareSocialMediaCard = (propertyData)=>{
                    const { enqueueSnackbar } = useSnackbar();

                    const [showOptions, setShowOptions] = useState(false);
                    const [showPropertyURL, setPropertyURL] = useState();
                    

                    useEffect(()=>{
                        if(propertyData){
                            createShortUrl(propertyData.propertyData);
                        }
                    },[propertyData]);

                    const createShortUrl = async (propertyData) => {
                        const res = await urlShortnerServices.createShortUrl({listingId: propertyData._id});
                        if(res && res.success){
                            const currentURL = window.location.origin;
                            setPropertyURL(currentURL + res.data);
                    }
                }
                        
                        // const url = 'https://example.com'; // Replace with your URL
                        const title = 'Check out this awesome content!'; // Replace with your title
                  
                    const showHideShareButtons = () =>{
                        setShowOptions(!showOptions);
                    }

                    const shareImageOnWhatsApp = () => {
                        // Replace 'imageUrl' with the actual URL of your image
                        const imageUrl = 'https://realx.in/assets/images/landing/section1.jpg';
                      
                        // Use the Web Share API if available
                        if (navigator.share) {
                          navigator.share({
                            title: 'Share via WhatsApp',
                            text: 'Check out this image!',
                            url: imageUrl,
                          })
                            .then(() => console.log('Shared successfully'))
                            .catch((error) => console.error('Error sharing:', error));
                        } else {
                          console.log('Web Share API not supported. Display alternative UI or provide instructions.');
                          // Optionally, you can open the WhatsApp web page with a pre-filled message
                          window.open(`https://wa.me/?text=${encodeURIComponent(`Check out this image: ${imageUrl}`)}`);
                        }
                      };

                    const copyUrl = () => {
                        navigator.clipboard.writeText(showPropertyURL);
                        enqueueSnackbar("Copied to Clickboard", { variant: "success",anchorOrigin: { horizontal: "right", vertical: "top"  },autoHideDuration:800})
                        setShowOptions(!showOptions);

                    };


                    return (            
                    <Grid className="share-button">
                    <IconButton sx={{backgroundColor: "#ebebeb"}}>
                    <ShareIcon
                        onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        showHideShareButtons();
                        }}

                        
                    />
                    </IconButton>

                    <Grid sx={{ marginTop: "10px" }}>
                    {showOptions && (
                        <div className="share-options">
                        <WhatsappShareButton
                                url={showPropertyURL}  // Replace with the actual URL of your website
                                title={'Real assets: Digital Holding - Invest in high return properties. Starting as low as ₹5000.  Check it out'}
                                separator=": "
                                onClick={shareImageOnWhatsApp}
                            >
                                <WhatsappIcon className="socal-media-icon" />
                        </WhatsappShareButton>

                        <FacebookShareButton url={showPropertyURL} quote={title}>
                            <FacebookIcon className="socal-media-icon" />
                        </FacebookShareButton>

                        <TwitterShareButton url={showPropertyURL} title={title}>
                            <TwitterIcon className="socal-media-icon" />
                        </TwitterShareButton>

                        <Box
                            className="socal-media-icon"
                            onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            copyUrl();
                            }}
                            sx={{
                            borderRadius: "100%",
                            width: 40,
                            height: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0.5,
                            bgcolor: "#FFFFFF",
                            position: "relative",
                            backgroundColor: "white",
                            overflow: "hidden",
                            "&::before": {
                                content: "''",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                borderRadius: "18px",
                                backgroundColor: "white",
                                boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                filter: "blur(50px) brightness(12%)",
                                zIndex: 0,
                            },
                            }}
                        >
                            <CustomIcon
                            name="copy"
                            sx={{ bgcolor: "#FFF", backgroundColor: "#333333" }}
                            />
                        </Box>
                        </div>
                    )}
                    </Grid>
                </Grid>
                );

}


const PropertyInfoCard = ({propertyData}) =>{
    const [info,setInfo] = useState([]);
    const [address,setAddress] = useState("");

    useEffect(()=>{
        chooseInformation();
        setAddress(`${propertyData?.project?.location?.streetAddressOne}, ${propertyData?.project?.location?.streetAddressTwo?propertyData?.project?.location?.streetAddressTwo+" ,":"" } ${propertyData?.project?.location?.landmark}, ${propertyData?.project?.location?.city}, ${propertyData?.project?.location?.state} ${propertyData?.project?.location?.pincode}`)

    // eslint-disable-next-line
    },[propertyData])

    const chooseInformation = () =>{
        let temp = [];
        if(propertyData?.propertyType === "Residential"){
            temp = [
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Unit Type",
                    value:propertyData?.basicInfo?.unitType +" BHK"
                },
                {
                    title:"Unit/Flat No.",
                    value:propertyData?.basicInfo?.flatNo
                },
                {
                    title:"Floor no",
                    value:propertyData?.basicInfo?.floorNO
                },
                {
                    title:"Wing",
                    value:propertyData?.basicInfo?.wing
                },
                {
                    title:"2- Wheeler Parking",
                    value:propertyData?.characteristics?.twoWheelerParkingAlloted
                },
                {
                    title:"4- Wheeler Parking",
                    value:propertyData?.characteristics?.fourWheelerParkingAlloted
                },
                {
                    title:"Total Builtup Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"Carpet Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.carpetArea,false)+ " sqft"
                },
            ];
        }
        else if(propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail"){
            temp = [
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Unit No.",
                    value:propertyData?.basicInfo?.unitNo
                },
                {
                    title:"Floor no",
                    value:propertyData?.basicInfo?.floorNO
                },
                {
                    title:"Wing",
                    value:propertyData?.basicInfo?.wing
                },
                {
                    title:"2- Wheeler Parking",
                    value:propertyData?.characteristics?.twoWheelerParkingAlloted
                },
                {
                    title:"4- Wheeler Parking",
                    value:propertyData?.characteristics?.fourWheelerParkingAlloted
                },
                {
                    title:"Total Builtup Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"Carpet Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.carpetArea,false)+ " sqft"
                },
            ];
        }
        else if(propertyData?.propertyType === "Warehouse"){
            temp = [
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Warehouse/Unit No",
                    value:propertyData?.basicInfo?.warehouseNo
                },
                {
                    title:"Floor no",
                    value:propertyData?.basicInfo?.floorNO
                },
                {
                    title:"2- Wheeler Parking",
                    value:propertyData?.characteristics?.twoWheelerParkingAlloted
                },
                {
                    title:"4- Wheeler Parking",
                    value:propertyData?.characteristics?.fourWheelerParkingAlloted
                },
                {
                    title:"Total Builtup Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"Carpet Area",
                    value:convertToIndianFormat(propertyData?.characteristics?.carpetArea,false)+ " sqft"
                },
            ];
        }
        else if(propertyData?.propertyType === "Land"){
            temp = [
                {
                    title:"Property Type",
                    value:propertyData?.propertyType
                },
                {
                    title:"Plot/Unit No",
                    value:propertyData?.basicInfo?.plotNo
                },
                {
                    title:"Plot area",
                    value:convertToIndianFormat(propertyData?.characteristics?.builtupArea,false)+ " sqft"
                },
                {
                    title:"FSI",
                    value:propertyData?.basicInfo?.fsi
                },
                // {
                //     title:"Construction Type",
                //     value:"Not Applicable"
                // },
                // {
                //     title:"SDR",
                //     value:convertToIndianFormat(propertyData?.basicInfo?.sdr)+ " sqft"
                // },
            ];
        }


        if (propertyData?.propertyStage?.constructedWithOC) {
            temp.push(
                {
                    title: "Construction Type",
                    value: propertyData?.propertyType !== "Land" ? "Constructed With OC" : "Plotted"
                },
                {
                    title: "Age Of Property",
                    value: propertyData?.propertyStage?.ageOfProperty + " years"
                }
            );
        } else if (propertyData?.propertyType !== "Land") {
            temp.push(
                {
                    title: "Construction Type",
                    value: "Under Construction"
                },
                {
                    title: "% Completion",
                    value: propertyData?.propertyStage?.completion + "%"
                },
                {
                    title: "Completion time",
                    value: propertyData?.propertyStage?.timeInCompletion + " years"
                }
            );
        } else {
            temp.push(
                {
                    title: "Construction Type",
                    value: "Not Plotted"
                }
            );
        }


        temp.push({
            title:"City",
            value:propertyData?.project?.location?.city
        },)
        // console.log(temp);
        setInfo(temp);
    }
    return (
      <Card>
        <CardContent>
          <Typography variant="h4">Property Information</Typography>
          <Divider
            sx={{
              width: "calc(100% + 80px)",
              margin:'15px -40px 24px',
            }}
          />

          <Box sx={{ my: 3 }}>
            <Stack mb={{ md: 3, xs: 2 }} direction={"row"} spacing={2}>
              <Box
                sx={{
                  borderRadius: "18px",
                  bgcolor: "primary.light",
                  padding: "8px 24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <Typography variant="chipText" sx={{ color: "primary.main" }}>
                  {propertyData?.propertyType}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "18px",
                  bgcolor: "primary.light",
                  padding: "8px 24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <Typography variant="chipText" sx={{ color: "primary.main" }}>
                  {propertyData?.tenancyType}
                </Typography>
              </Box>
            </Stack>

          </Box>

          <Grid container columnSpacing={7} rowSpacing={3}>
            {info.map((e) => {
              return (
                <Grid item>
                  <Stack>
                    <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                      {e.title}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {e.value}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>

          <Box
            sx={{
              my: 3,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
              Location
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {address}
            </Typography>
          </Box>

          <Box
            sx={{
              my: 3,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
              Description
            </Typography>
            
            <Typography
              variant="body1"
              // sx={{minHeight:{md:"312px",xs:"auto"}}}
              dangerouslySetInnerHTML={{ __html: propertyData?.description }}
            />
          </Box>

          <Box
            sx={{
              my: 3,
            }}
          >
            <ProjectDetailCard propertyData={propertyData}/>
            </Box>

        </CardContent>
      </Card>
    );
}

const LegalInfoCard = ({propertyData}) =>{
    const {noOfFrax,ratePerFrax,noOfRemFrax,totalCost, totalRetailCost,actualRPF} = useContext(PropertyContext);
    const [target,setTarget] = useState(0);

    useEffect(()=>{
       let temp = calcTarget(noOfFrax,noOfRemFrax,actualRPF);
       if(temp != null) setTarget(temp)
    // eslint-disable-next-line
    },[noOfFrax,actualRPF,noOfRemFrax])

    

    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
               { propertyData?.legal &&
               <Grid>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Legal Information</Typography>
                    {/* <Button onClick={()=>setBuy(true)} variant="contained" >Invest Now</Button> */}
                </Stack>
                <Divider sx={{
                        width:"calc(100% + 80px)",
                        margin:'15px -40px 24px'   
                    }} />

                <Stack direction={"column"} spacing={3} mt={3}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Registrant</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{ propertyData?.legal?.registrant || "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Trustee (Registrant)</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{ propertyData?.legal?.trustee || "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Token Issuer</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{ propertyData?.legal?.toeknIssuer || "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >DD Firm</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{ propertyData?.legal?.DDFirm || "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Asset Manager</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{ propertyData?.legal?.assetManager|| "-"}</Typography>
                    </Stack>

                </Stack>
                </Grid>
            }
            </CardContent>
        </Card>
    )
}
 
// const FinancialsCard = () =>{
//     const data = [
//         {
//             title:"Expected ROI",
//             value:"12% yearly"
//         },
//         {
//             title:"Fraction Price",
//             value:"₹ 50,000"
//         },
//         {
//             title:"Total No. Of Fractions",
//             value:"Total 20"
//         },
//         {
//             title:"Rent Per Fraction",
//             value:"₹ 5000"
//         },
//         {
//             title:"Expiry Date",
//             value:"29th June 2021"
//         },
//     ];
//     const breakdown = [
//         {
//             title:"Gross Rent / Year",
//             value:"₹ 6,20,000"
//         },
//         {
//             title:"Gross Rent / Month",
//             value:"₹ 50,000"
//         },
//         {
//             title:"Monthly Costs",
//             value:"₹ 4,568"
//         },
//         {
//             title:"Net Rent / Month",
//             value:"₹ 50,000"
//         },
//         {
//             title:"Net Rent / Year",
//             value:"₹ 42,000"
//         },
//         {
//             title:"Total Value Of Property",
//             value:"₹ 80,00,000"
//         },
//         {
//             title:"Expected Income",
//             value:"18% yearly"
//         },
//     ]
//     return(
//         <Card>
//             <CardContent>
//                 <Typography variant="h4" >Financials</Typography>
//                 <Stack mt={3} direction={"column"}>
//                     {
//                         data.map((e,i)=>{
//                             return(<Box
//                                 sx={{
//                                     py:"14px",
//                                     pl:3,
//                                     pr:7,
//                                     bgcolor:i%2 ?  "background.default" :""
//                                 }}
//                             >
//                                 <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
//                                     <Typography variant="body2">{e.title}</Typography>
//                                     <Typography variant="body1" sx={{fontWeight:500}}>{e.value}</Typography>
//                                 </Stack>
//                             </Box>)
//                         })
//                     }
//                 </Stack>
//                 <Typography mt={5} variant="h4" >Breakdown</Typography>
//                 <Stack mt={3} direction={"column"}>
//                     {
//                         breakdown.map((e,i)=>{
//                             return(<Box
//                                 sx={{
//                                     py:"14px",
//                                     pl:3,
//                                     pr:7,
//                                     bgcolor:i%2 ?  "background.default" :""
//                                 }}
//                             >
//                                 <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
//                                     <Typography variant="body2">{e.title}</Typography>
//                                     <Typography variant="body1" sx={{fontWeight:500}}>{e.value}</Typography>
//                                 </Stack>
//                             </Box>)
//                         })
//                     }
//                 </Stack>
//             </CardContent>
//         </Card>
//     )
// }
const InvestmentAnalysisCard = () =>{
    const [data,setData] = useState([]);
    const {noOfFrax,ratePerFrax,propertyData,totalCost, totalRetailCost, isLead, leadMinInvestmentAmountToken, leadMinInvestmentAmountRCO} = useContext(PropertyContext);

    useEffect(()=>{
        let temp = isLead ? [
            {
                title: totalRetailCost > totalCost 
                ? `Total Cost of Purchase (Retail)`
                : "Total Cost of Purchase",
                value:convertToIndianFormat(totalRetailCost)
            },
                ...(totalRetailCost > totalCost?[{
                title:"Total Cost of Purchase (Discounted)",
                value:convertToIndianFormat(totalCost)
                }]:[]),
            {
                title:"Total Cost PSF (on saleable area)",
                value:convertToIndianFormat(Math.round((totalRetailCost/propertyData?.characteristics?.builtupArea)))
            },
                ...(totalRetailCost > totalCost ? [{
                title:"Discounted Total Cost PSF (on saleable area)",
                value:convertToIndianFormat(Math.round((totalCost)/propertyData?.characteristics?.builtupArea))
                }]:[]),
            {
                title:"Total Number of FRAX",
                value:convertToIndianFormat(noOfFrax,false)
            },
            {
                title:totalRetailCost > totalCost 
                      ? "Rate per FRAX (Retail)"
                      : "Rate per FRAX",
                value:convertToIndianFormat((totalRetailCost / (144 * propertyData?.characteristics?.builtupArea)).toFixed(2))
            },
                ...(totalRetailCost > totalCost ? [{
                title:"Rate per FRAX (Discounted)",
                value:convertToIndianFormat((ratePerFrax).toFixed(2))
                }]:[]),
            {
                title:"Minimum Investment Amount (Tokenization)",
                value:convertToIndianFormat(leadMinInvestmentAmountToken || "-")
            },
            {
                title:"Minimum Investment Amount (RCO)",
                value:convertToIndianFormat(leadMinInvestmentAmountRCO || "-")
            },
        ] : [
            {
                title:"Total Cost of Purchase",
                value:convertToIndianFormat(totalRetailCost)
            },
            {
                title:"Total Cost PSF (on saleable area)",
                value:convertToIndianFormat(Math.round((totalRetailCost/propertyData?.characteristics?.builtupArea)))
            },
            {
                title:"Total Number of FRAX",
                value:convertToIndianFormat(noOfFrax,false)
            },
            {
                title:"Rate per FRAX",
                value:convertToIndianFormat((totalRetailCost / (144 * propertyData?.characteristics?.builtupArea)).toFixed(2))
            },
            {
                title:"Minimum Investment Amount (Tokenization)",
                value:convertToIndianFormat((propertyData?.transactionDetails?.minInvestmentAmount))
            },
            {
                title:"Minimum Investment Amount (RCO)",
                value:convertToIndianFormat((propertyData?.transactionDetails?.minInvAmtOwnership))
            },
        ];

        if(propertyData?.tenancyType !== "Vacant"){
            const rentPerAnnum = propertyData?.tenancyDetails?.rentPerMonth*12
            const gys = rentPerAnnum/propertyData?.transactionDetails?.salePrice;
            const noys = (rentPerAnnum - propertyData?.tenancyDetails?.societyMaintenance - propertyData?.tenancyDetails?.propertyTax)/propertyData?.transactionDetails?.salePrice;
            const nyrs = (rentPerAnnum - propertyData?.tenancyDetails?.societyMaintenance - propertyData?.tenancyDetails?.propertyTax - ( 0.10 * rentPerAnnum) + (0.06 * propertyData?.tenancyDetails?.securityDeposit))/propertyData?.transactionDetails?.salePrice;
            temp.push(
                {
                    title:"Gross Yield (GYS)",
                    value:convertToIndianFormat(Math.round(gys))
                },
                {
                    title:"Net Operating Yield (NOYS)",
                    value:convertToIndianFormat(Math.round(noys))
                },
                {
                    title:"Net Realizable Yield (NRYS)",
                    value:convertToIndianFormat(Math.round(nyrs))
                },
                {
                    title:"Monthly Rent (Rent-CAM-Property Tax)",
                    value:convertToIndianFormat(Math.round(noys/12))
                },
            )
        }

        setData(temp)
    },[propertyData,noOfFrax,ratePerFrax,totalCost])

    return(
        <Card>
            <CardContent>
                <Typography variant="h4" >Investment Analysis</Typography>
                <Divider sx={{
                        width:"calc(100% + 80px)",
                        margin:'15px -40px 24px'
                    }} />
                <Stack mt={3} direction={"column"}>
                    {
                        data.map((e,i)=>{
                            return(<Box
                                sx={{
                                    py:"14px",
                                    pl:{md:3,xs:2},
                                    pr:{md:5,xs:2},
                                    bgcolor:i%2 ? "" : "background.default"
                                }}
                            >
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                    <Typography variant="body1">{e.title}</Typography>
                                    <Typography variant="body1" sx={{fontWeight:{md:500,xs:400}}}>{e.value}</Typography>
                                </Stack>
                            </Box>)
                        })
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

// const AmenitiesCard = () =>{
//     return(
//         <Card sx={{height:"fit-content"}}>
//             <CardContent>
//                 <Typography variant="h4" >Amenities</Typography>
//                 <Grid container sx={{mt:1}} columnSpacing={3} rowSpacing={2}>
//                     <Grid item>
//                         <Stack direction={"row"}
//                         spacing={2}
//                             sx={{
//                                 padding:"16px 32px",
//                                 backgroundColor:"background.default",
//                                 borderRadius:1,
//                                 alignItems:"center"
//                             }}
//                         >
//                             <CircleIcon sx={{width:24,height:24}} />
//                             <Typography variant="body2" >Swimming Pool</Typography>
//                         </Stack>
//                     </Grid>
//                     <Grid item>
//                         <Stack direction={"row"}
//                         spacing={2}
//                             sx={{
//                                 padding:"16px 32px",
//                                 backgroundColor:"background.default",
//                                 borderRadius:1,
//                                 alignItems:"center"
//                             }}
//                         >
//                             <CircleIcon sx={{width:24,height:24}} />
//                             <Typography variant="body2" >Power Backup</Typography>
//                         </Stack>
//                     </Grid>
//                     <Grid item>
//                         <Stack direction={"row"}
//                         spacing={2}
//                             sx={{
//                                 padding:"16px 32px",
//                                 backgroundColor:"background.default",
//                                 borderRadius:1,
//                                 alignItems:"center"
//                             }}
//                         >
//                             <CircleIcon sx={{width:24,height:24}} />
//                             <Typography variant="body2" >Jogging/Cycling Track</Typography>
//                         </Stack>
//                     </Grid>
//                     <Grid item>
//                         <Stack direction={"row"}
//                         spacing={2}
//                             sx={{
//                                 padding:"16px 32px",
//                                 backgroundColor:"background.default",
//                                 borderRadius:1,
//                                 alignItems:"center"
//                             }}
//                         >
//                             <CircleIcon sx={{width:24,height:24}} />
//                             <Typography variant="body2" >Kids Play Area</Typography>
//                         </Stack>
//                     </Grid>
//                     <Grid item>
//                         <Stack direction={"row"}
//                         spacing={2}
//                             sx={{
//                                 padding:"16px 32px",
//                                 backgroundColor:"background.default",
//                                 borderRadius:1,
//                                 alignItems:"center"
//                             }}
//                         >
//                             <CircleIcon sx={{width:24,height:24}} />
//                             <Typography variant="body2" >Gymnasium</Typography>
//                         </Stack>
//                     </Grid>
//                 </Grid>
//             </CardContent>
//         </Card>
//     )
// }

const TenancyDetailCard = ({propertyData}) =>{
    const [data,setData] = useState([]);

    useEffect(()=>{
        let temp= [
            {
                title:"Tenant Name",
                value:propertyData?.tenancyDetails?.tenantName
            },
            {
                title:"Tenant Type",
                value:`${propertyData?.tenancyDetails?.tenantType}`
            },
            {
                title:"Tenancy Type",
                value:`${propertyData?.tenancyType}`
            },
            {
                title:"Tenancy Start",
                value:`${moment.unix(propertyData?.tenancyDetails?.tenancyStart).format("Do MMMM YYYY")}`
            },
            {
                title:"Tenancy End",
                value:`${moment.unix(propertyData?.tenancyDetails?.tenancyEnd).format("Do MMMM YYYY")}`
            },
            {
                title:"Rent Per Month",
                value:convertToIndianFormat(propertyData?.tenancyDetails?.rentPerMonth)
            },
            {
                title:"Monthly Rent PSF (on saleable area)",
                value:convertToIndianFormat((propertyData?.tenancyDetails?.rentPerMonth/propertyData?.characteristics?.builtupArea))
            },
            {
                title:"Rent Escalation (In Percentage)",
                value:`${propertyData?.tenancyDetails?.rentEscalation}%`
            },
            {
                title:"Escalation Frequency (in Months)",
                value:`${propertyData?.tenancyDetails?.escalationFrequency} Months`
            },
            {
                title:"Facility/Society Maintenance (CAM) (Per Annum)",
                value:convertToIndianFormat(propertyData?.tenancyDetails?.societyMaintenance)
            },
            {
                title:"Property Tax (if paid by tenant)",
                value:convertToIndianFormat(propertyData?.tenancyDetails?.propertyTax)
            },
            {
                title:"RealX Standard Deductions (From Rent)",
                value:convertToIndianFormat(propertyData?.tenancyDetails?.realxStandardDeductions)
            },
            {
                title:"Security Deposit",
                value:convertToIndianFormat(propertyData?.tenancyDetails?.securityDeposit)
            },
            {
                title:"Notice Period (In Months)",
                value:`${propertyData?.tenancyDetails?.noticePeriod} Months`
            },
            {
                title:"License Period (Years)",
                value:`${propertyData?.tenancyDetails?.licensePeriod} Years`
            },
            {
                title:"Lock In Period (Years)",
                value:`${propertyData?.tenancyDetails?.lockPeriod} Years`
            },
            // {
            //     title:"Security Deposit",
            //     value:`₹${convertToIndianFormat(propertyData?.tenancyDetails?.tenantName)}`
            // },
        ];
        setData(temp)
    },[propertyData])


    return (
        <Card>
            <CardContent>
                <Typography variant="h4" >Tenant Details</Typography>
                <Divider
                    sx={{
                        width: "calc(100% + 80px)",
                        margin:'15px -40px 24px'
                    }}
                />
                <Stack mt={3} direction={"column"}>
                    {
                        data.map((e,i)=>{
                            return(<Box
                                sx={{
                                    py:"14px",
                                    pl:{md:3,xs:2},
                                    pr:{md:5,xs:2},
                                    bgcolor:i%2 ? "" : "background.default"
                                }}
                            >
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                    <Typography variant="body1">{e.title}</Typography>
                                    <Typography variant="body1" sx={{fontWeight:{md:500,xs:400}}}>{e.value}</Typography>
                                </Stack>
                            </Box>)
                        })
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

const LocationCard = ({propertyData}) =>{
    const [location,setLocation] = useState({
        lat:null,
        lng:null
    })
    const libraries=["places"];
    const {isLoaded,loadError} = useLoadScript({
        googleMapsApiKey:"AIzaSyDYb-K3RBy_Fo3jWSySQOIIs5J0fGCIorU",
        libraries
    });

    useEffect(()=>{
        setLocation(propertyData?.project?.location?.coordinates);
    },[propertyData]);

    useEffect(()=>{
        getLocation()
        // eslint-disable-next-line
    },[])

    function errorHandler(err) {
        if (err.code === 1) {
            console.log("Error: Access is denied!");
            // setAccessed(false)
        } else if (err.code === 2) {
            console.log("Error: Position is unavailable!");
        }
    }

    function getLocation() {
        if (navigator?.geolocation) {
            // timeout at 60000 milliseconds (60 seconds)
            var options = {
                timeout: 60000
            };
            navigator.geolocation.getCurrentPosition(()=>{}, errorHandler, options);
        } else {
            console.log("Sorry, browser does not support geolocation!");
        }
    }

    if(loadError) console.log(loadError);
    if(!isLoaded) return "Loading Maps";

    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Location</Typography>
                <Divider sx={{
                        width:"calc(100% + 80px)",
                        margin:'15px -40px 24px'  
                    }} />
                <Box width={"100%"} height={"270px"} mt={3}>
                    <GoogleMap 
                        clickableIcons={false}
                        streetView={false}
                        options={{
                            fullscreenControl: false,
                            streetViewControl:false,
                            mapTypeControl:false,
                        }}
                        // onLoad={(map)=>setMap(map)}
                        mapContainerStyle={{height:"100%",width:"100%"}} zoom={15} center={location}
                       >
                            {/* <Marker key={0} position={location}/> */}
                            <Marker key={1} position={location}/>
                    </GoogleMap>
                </Box>
            </CardContent>
        </Card>
    )
}

const InvestNowCard = ({setBuy, propertyData}) =>{
    console.log(propertyData);
    const user = useSelector(state=>state.user.data);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [kyc,setKyc] = useState(false);
    const [showSignUp,setShowSignUp] = useState(false);

    const {noOfFrax,ratePerFrax,noOfRemFrax,totalCost, totalRetailCost,actualRPF} = useContext(PropertyContext);
    const [target,setTarget] = useState(0);
    const [showLogin,setShowLogin] = useState(false);
    const [loading, setLoading]=useState(false);

    const handleBuy = () =>{
        if(checkLogin()){
            if(user?.status === "APPROVED"){
                 setBuy(true);
                navigate(`/dashboard/property/${state.id}/invest`,{state:{id:state.id}})
            }
            else if(user?.status === "KYC_COMPLETE")
            {
                setKyc(true);
            }
            else 
            {
                setShowSignUp(true);
            }
        }
        else{
            setShowLogin(true);
        }
        
    }
    
    
    const getTitle = () =>{
        if(propertyData?.propertyType === "Residential") return `${propertyData?.basicInfo?.flatNo}, Wing-${propertyData?.basicInfo?.wing}`
        if(propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail") return `${propertyData?.basicInfo?.unitNo}, Wing-${propertyData?.basicInfo?.wing}`
        if(propertyData?.propertyType === "Land") return `Plot No. ${propertyData?.basicInfo?.plotNo}`
        if(propertyData?.propertyType === "Warehouse") return `Warehouse No. ${propertyData?.basicInfo?.warehouseNo}`
        return "";
    }
    
    const handleButtonClick = () => {
        if (propertyData?.telegram) {
            setLoading(true)         
            window.open(propertyData.telegram, '_blank'); // Open the link in a new tab
            setLoading(false)
        } else {
          console.warn('No link available in propertyData');
        }
      };

    useEffect(()=>{
       let temp = calcTarget(noOfFrax,noOfRemFrax,actualRPF);
       if(temp != null) setTarget(temp)
    // eslint-disable-next-line
    },[noOfFrax,actualRPF,noOfRemFrax]);
return(
    <Card sx={{ height: 'fit-content' }}>
    <CardContent>

<Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={9}>
     
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" sx={{ color: '#B0B0B0' }}>Target</Typography>
                <Stack direction="row">
                  <Typography variant="body1" sx={{ color: "primary.main" }}>{convertToIndianFormat(target)}</Typography>
                  <Typography variant="body1" sx={{ color: '#B0B0B0' } }>
                    &nbsp;/ {convertToIndianFormat(totalRetailCost)}
                  </Typography>
                </Stack>
              </Stack>
              <LinearProgress variant="determinate" value={calculatePercent(target, totalCost)} sx={{ mt: 2 }} />
            </Stack>
  
      </Grid>
      {
        target !=totalRetailCost &&
        <Grid item xs={3}>
            <Stack sx={{marginLeft: 2}}>
                    <Button
                    fullWidth
                    onClick={() => setBuy(true)}
                    variant="contained"
                    disabled={propertyData?.pauseProperty||propertyData?.hideProperty}
                  >
                    Invest Now
                  </Button>
            </Stack>
        </Grid>
      }
        {
        propertyData?.telegram &&
        <Grid item>
            <LoadingButton 
                loading={loading}
                onClick={handleButtonClick}
                //   sx={{ borderColor: '#039be5', color: '#039be5', '&:hover': { borderColor: 'darkblue', color: 'darkblue' } }}
                  startIcon={<img src="/assets/logos/telegram.png" alt="Telegram Icon" style={{ width: 24, height: 24}} />}
                  variant="outlined"
                  loadingIndicator={
                    <CircularProgress size={30} sx={{ color: 'red' }} /> 
                  }>
                <Typography 
                sx={{
                    fontFamily:"Proxima Nova Rg",
                    fontWeight:600,
                    fontSize:{xs:14,sm: 16, md: 18, lg: 20,xl:20},
                    }}>
                    Join deal telegram group
                </Typography>
            </LoadingButton> 
        </Grid>
      }
    </Grid>
    </CardContent>
        </Card>
)
}

const StatisticsCard = ({setBuy}) =>{
    const {noOfFrax,ratePerFrax,noOfRemFrax,totalCost, totalRetailCost ,propertyData, isLead, leadSalePricePSF, actualRPF} = useContext(PropertyContext);
    const retailBasePricePSF=propertyData?.transactionDetails?.retailBasePricePSF;
    const [target,setTarget] = useState(0);

    useEffect(()=>{
        let temp = calcTarget(noOfFrax,noOfRemFrax,actualRPF);
        if(temp != null) setTarget(temp)
    },[noOfFrax,noOfRemFrax,actualRPF])
    

    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
               
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Transaction Details</Typography>
                    {/* <Button onClick={()=>setBuy(true)} variant="contained" >Invest Now</Button> */}
                </Stack>
                <Divider sx={{
                        width:"calc(100% + 80px)",
                        margin:'15px -40px 24px'  
                    }} />

                <Stack direction={"column"} spacing={3} mt={3}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Sale Price</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails?.retailBasePricePSF * propertyData?.characteristics.builtupArea))|| "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Sale Price PSF (On Saleable Area)</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails?.retailBasePricePSF))|| "-"}</Typography>
                    </Stack>
                    {isLead && retailBasePricePSF > leadSalePricePSF && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Discounted Sale Price PSF (On Saleable Area)</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(leadSalePricePSF))|| "-"}</Typography>
                    </Stack>}
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Stamp Duty</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.status == "LIVE" ? propertyData?.transactionDetails.retailBasePricePSF * propertyData?.characteristics.builtupArea * propertyData?.transactionDetails?.stampDuty / 100 : propertyData?.transactionDetails?.stampDutyINR)) || "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Registration Charges</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails?.registrationCharges))|| "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >GST</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails.retailBasePricePSF * propertyData?.characteristics.builtupArea * propertyData?.transactionDetails?.gst / 100))|| "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >RealX Sinking Fund @{propertyData?.transactionDetails?.sinkingFund ? (Math.round(propertyData?.transactionDetails?.sinkingFund) / propertyData?.characteristics?.builtupArea).toFixed(1) : 0} Per sqft</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}}>
                            {convertToIndianFormat(Number(propertyData?.transactionDetails?.sinkingFund?.toFixed(2))) || "-"}
                        </Typography>

                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Due Diligence And Other Closing Expenses</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails?.closingExpenses))|| "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >RealX Placement & Facilitation Fees</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails?.facilitationFees))}</Typography>
                    </Stack>
                    {
                        propertyData?.transactionDetails?.variableExtraCharges?.map((_item)=>{
                            return(
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography variant="body1" >{_item.expenseTitle}</Typography>
                                    <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(_item.expenseAmount))|| "-"}</Typography>
                                </Stack>
                            )
                        })
                    }
                    {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Commitment Amount</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{convertToIndianFormat(Math.round(propertyData?.transactionDetails?.minCommitmentAmount))|| "-"}</Typography>
                    </Stack> */}
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1" >Expected Transaction Closure</Typography>
                        <Typography variant="body1" sx={{fontWeight:500}} >{moment.unix(propertyData?.expiryDate).format("Do MMM YYYY")|| "-"}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

const DocumentsCard = ({propertyData,url}) =>{
    const [documents,setDocumnets] = useState([]);
    useEffect(()=>{
        if(propertyData){
            // console.log(propertyData.documents)
            setDocumnets(propertyData.documents)
        }
    },[propertyData]);
    return(
        <Card sx={{height:"fit-content"}}>
            <CardContent>
                <Typography variant="h4" >Documents</Typography>
                <Divider sx={{
                        width:"calc(100% + 80px)",
                        margin:'15px -40px 24px'  
                    }} />
                <Stack mt={2.5} direction={"column"} spacing={2}>
                    {
                        documents.map((doc)=>{
                            return(
                                <Stack direction={"row"}
                                    spacing={2}
                                    sx={{
                                        padding:"20px 32px",
                                        backgroundColor:"background.default",
                                        borderRadius:1,
                                        alignItems:"center"
                                    }}
                                >
                                    <CustomIcon name="file" sx={{width:20,height:20}} />
                                    <Typography variant="body1" flex={1} >{getDocName(doc.docName)}</Typography>
                                    <Typography onClick={()=>{window.open(`${url}${doc.docUrl}`, '_blank');}} variant="body1" sx={{color:"primary.main",cursor:"pointer"}} >View Document</Typography>
                                </Stack>
                            )
                        })
                    }
                    {/* <Stack direction={"row"}
                        spacing={2}
                        sx={{
                            padding:"20px 32px",
                            backgroundColor:"background.default",
                            borderRadius:1,
                            alignItems:"center"
                        }}
                    >
                        <CustomIcon name="file" sx={{width:20,height:20}} />
                        <Typography variant="body2" flex={1} >Satbara</Typography>
                        <Typography onClick={()=>{window.open(`/assets/images/listing/docs/${propertyData?.basicInfo?.plotNo}_Satbara.pdf`, '_blank');}} variant="body2" sx={{color:"primary.main",cursor:"pointer"}} >View Document</Typography>
                    </Stack>
                    <Stack direction={"row"}
                        spacing={2}
                        sx={{
                            padding:"20px 32px",
                            backgroundColor:"background.default",
                            borderRadius:1,
                            alignItems:"center"
                        }}
                    >
                        <CustomIcon name="file" sx={{width:20,height:20}} />
                        <Typography variant="body2" flex={1} >List of Amenities</Typography>
                        <Typography onClick={()=>{window.open("/assets/images/listing/docs/amenities.pdf", '_blank');}} variant="body2" sx={{color:"primary.main",cursor:"pointer"}} >View Document</Typography>
                    </Stack> */}
                </Stack>
            </CardContent>
        </Card>
    )
}

const ProjectDetailCard = ({propertyData,url}) =>{
    const navigate = useNavigate()
    const [images,setImages] = useState([]);

    useEffect(()=>{
        if(propertyData){
            setImages(propertyData.projectImages.map((e)=>url+e.docUrl))
        }
    },[propertyData,url])
    return(
                <Grid>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack>

                <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
              Project Name
            </Typography>
                    <Typography variant="h4" >{propertyData?.project?.projectName}</Typography>
                    </Stack>
                    <Button onClick={()=>navigate("/dashboard/project",{state:{id:propertyData?.project?._id}})} variant="outlined" >View Project</Button>
                </Stack>
                
                {/* <Divider sx={{
                        width:"calc(100% + 80px)",
                        mx:-5,
                        my:3    
                    }} /> */}
                {/* <Stack direction={"row"} spacing={{md:5,xs:3}}>
                    {
                        images.length > 0 &&
                        <>
                            <Box
                                sx={{
                                    height:{md:142,xs:100},
                                    flex:1,
                                    background:`url('${images[0]}')`,
                                    borderRadius:"15px",
                                    backgroundSize:"cover",
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"center",
                                }}
                            >
                            </Box>
                            <Box
                                sx={{
                                    height:{md:142,xs:100},
                                    flex:1,
                                    background:`url('${images[1]}')`,
                                    borderRadius:"15px",
                                    backgroundSize:"cover",
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"center",
                                }}
                            >
                            </Box>
                        </>
                    }
                </Stack> */}
                {/* <Stack direction={"column"} spacing={3} mt={5}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body2" >No. Of Properties</Typography>
                        <Typography sx={{fontSize:18,fontWeight:500}} >20 Properties Listed</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body2" >Configurations</Typography>
                        <Typography sx={{fontSize:18,fontWeight:500}} >1 BHK, 2 BHK, 3 BHK</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body2" >Buildup Area</Typography>
                        <Typography sx={{fontSize:18,fontWeight:500}} >10,000 Sqft</Typography>
                    </Stack>
                </Stack> */}
                {/* <Divider sx={{
                        width:"calc(100% + 80px)",
                        mx:-5,
                        my:3    
                    }} />
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="body1" >{propertyData?.developer?.username}</Typography>
                    <Button onClick={()=>navigate("/marketplace/developer",{state:{id:propertyData?.developerId}})} variant="outlined" >View Developer</Button>
                </Stack> */}

</Grid>
    )
}

const WhyInvestCard = ({propertyData})=>{
    return (
        <Card sx={{pl:2}}>
            <CardContent>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h4" >Why should I invest </Typography>
            </Stack>
            <Divider sx={{ width:"calc(100% + 80px)",  margin:'15px -40px 24px' }} />
                <Stack direction={"row"} spacing={2}>
                    <Typography dangerouslySetInnerHTML={{__html: propertyData?.whyInvest}} variant="body1"></Typography>
                </Stack>
        </CardContent>
    </Card>
    )
}

const HighlightCard = ({propertyData})=>{
    const [childRef,setChildRef] = useState({});

    const scrollLeft = () =>{
        if(document.body.clientWidth<900){
            childRef.scrollLeft(document.body.clientWidth - 56);
        }
        else{
            childRef.scrollLeft();
        }
    }

    const scrollRight = () =>{
        if(document.body.clientWidth<900){
            childRef.scrollRight(document.body.clientWidth - 56);
        }
        else{
            childRef.scrollRight();
        }
    }
      


    return(
        <Card>
            <CardContent>
            <Typography variant="h4" >Highlights</Typography>
                <Divider
                    sx={{
                        width: "calc(100% + 80px)",
                        margin:'15px -40px 24px'
                    }}
                />
            

                <Stack direction={"column"}>
                {
                        propertyData?.highlights?.map((e,i)=>{
                            return(
                                <Grid item md={"auto"} xs={12} sx={{p:0.1}}>
                                    <Box sx={{borderRadius:1.5,boxShadow:{md:"0px 0px 4px #00000014",xs:"none"},padding:{md:2,xs:0},minWidth:{md:415,xs:"calc( 100vw - 80px)"}}}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Typography variant="h4" color={"primary.main"}>{(i+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}</Typography>
                                            <Typography style={{color: '#333333'}} variant="body3" dangerouslySetInnerHTML={{__html: e.highlightValue}}  sx={{lineHeight: 2}}></Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Stack>


                {/* <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Highlights</Typography>
                    <Stack direction={"row"} spacing={1.5}>
                        <Box onClick={scrollRight} sx={{bgcolor:"background.default",width:32,height:32,borderRadius:"100%",cursor:"pointer",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <ChevronLeftIcon fontSize="small" sx={{color:"divider"}} />
                        </Box>
                        <Box onClick={scrollLeft} sx={{bgcolor:"background.default",width:32,height:32,borderRadius:"100%",cursor:"pointer",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <ChevronRightIcon fontSize="small" sx={{color:"divider"}} />
                        </Box>
                    </Stack>
                </Stack> */}
                {/* <Stack mt={3} direction={"row"} spacing={3} flexWrap={"nowrap"} overflow={"auto"}> */}
                {/* <CustomSlider navigation={true} myRef={setChildRef} sx={{columnGap:3,mt:3,px:0}} >
                    {
                        propertyData?.highlights?.map((e,i)=>{
                            return(
                                <Grid item md={"auto"} xs={12} sx={{p:0.1}}>
                                    <Box sx={{borderRadius:1.5,boxShadow:{md:"0px 0px 4px #00000014",xs:"none"},height:"100%",padding:{md:2,xs:0},maxWidth:{md:415,xs:"calc( 100vw - 80px)"},minWidth:{md:415,xs:"calc( 100vw - 80px)"}}}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Typography variant="h4" color={"primary.main"}>{(i+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}</Typography>
                                            <Typography dangerouslySetInnerHTML={{__html: e.highlightValue}} variant="body2"></Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </CustomSlider> */}
                {/* </Stack> */}
            </CardContent>
        </Card>
    )
}