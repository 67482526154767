
import { server } from "../utils/server";

const updateDLTCreationStatusHUF = (data) =>{
    return server.post("/huf/features/updateDLTCreationStatus",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}
const createBlockChainAcc = (data) =>{
    return server.post("huf/features/createWallet",data)
    .then(res=>{
        
        return res.data;
    })
    .catch(err=>{
        console.log(err);
        return null;
    })
}

const getDltWalletOfHuf = (hufId) => {
    return server.get(`huf/auth/getDltWallet/${hufId}`)
        .then(res => {
            
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return null;
        })
}

const removeDLTAccount = (data) =>{
    return server.post("/huf/features/removeDLTAccount",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const updatePrimaryDLTAccount = (data) =>{
    return server.post("/huf/features/updatePrimaryDLTAccount",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

 export default {
    updateDLTCreationStatusHUF,
    createBlockChainAcc,
    getDltWalletOfHuf,
    updatePrimaryDLTAccount,
    removeDLTAccount
}