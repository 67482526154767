import { Box, Card, CardContent, Dialog, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

export default function ModalComponent(props){
    return(
        <Dialog
        
        maxWidth={"auto"}
        scroll={"body"}
        PaperProps={{sx: { overflow: "auto", position: "relative" }}}
        {...props}
        onClose={props.handleClose}
            >
            <Box
                onClick={props.handleClose}
                sx={{
                    position: "absolute",
                    top: 2,
                    right: 5,
                    cursor: "pointer",
                    zIndex: 1, 
                }}>
                <CloseIcon sx={{ width: {md:17,xs:15}, height: {md:17,xs:15}, color: "#000", stroke: "#000", strokeWidth: 2, }} />
            </Box>
            <Card sx={{p:3}}>           
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography sx={props.titleStyle} variant="modalTitle">{props.title}</Typography>
                    </Stack>
                    {
                        props.children
                    }
            </Card>
        </Dialog>
    )
}