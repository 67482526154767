import { Box, Button, Dialog, Stack, Typography } from '@mui/material'
import React from 'react'

export const ConfirmActionModal = ({open,setOpen,actionText,handleYes,data}) => {

    function handleClose (){
        setOpen(false)
    }
    
  return (
    <Dialog
    open={open}
    onClose={handleClose}
    fullWidth={true}
    maxWidth={'sm'}
    >
        <Stack padding={5}  rowGap={5} alignItems={'center'}>
            <Typography>{actionText ? actionText :"Are You Sure ?"} </Typography>
            <Box>
            <Button variant="contained" size="small" onClick={() => { handleYes(data); handleClose() }} >Confirm</Button>
            <Button variant="outlined" size="small" onClick={handleClose} sx={{ml:1}}>Cancel</Button>
            </Box>
        </Stack>

    </Dialog>
  )
}
