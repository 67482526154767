import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import MiscLayout from "./miscLayout";

export default function WhyRealX(){

    const tabs = [
        {
            name:"Advantage RealX",
            component:<WhyRealXSub />
        },
        {
            name:"For Investors",
            component:<ForInvestors />
        },
        {
            name:"A note on Regulations",
            component:<Regulations />
        },
    ]
    return(
        <MiscLayout tabs={tabs} title={"Why RealX"} />
    )
}

const WhyRealXSub = () =>{
    return(
        <Box>
            <AdvantageOfRealX />
        </Box>
    )
}

const ForInvestors = () =>{
    return(
        <Stack spacing={{md:4,xs:2.5}}>
            <Stack spacing={1.5} mb={{md:0,xs:2.5}}>
                <Box 
                    sx={{
                        borderRadius:"10px",
                        bgcolor:"primary.light",
                        px:{md:4,xs:2.5},
                        py:{md:1,xs:2},
                        width:"fit-content",
                        textAlign:{md:"center"}
                    }}
                >
                    <Typography variant="miscItalic">90% of all millionaires made fortune with real estate investment.<br /> And this did not stop even during the pandemic</Typography>
                </Box>
                <Typography variant="body3">Real estate continues to be the first choice for investment with 57% respondents favoring it over other assets like equities & mutual funds, FDs and gold. Interestingly, the stock market – despite being volatile in nature – is the second preferred choice with 24% votes.</Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">RealX was built singularly with investors in mind</Typography>
                <Typography variant="body3">It follows a next generation legal and commercial model for Fractional Ownership that brings in unique advantages for the new generation property investors</Typography>
            </Stack>
            <Box sx={{width:"100%",height:1.2,bgcolor:"divider"}} />
            <AdvantageOfRealX />
        </Stack>
    )
}

const AdvantageOfRealX = () =>{

    const data = [
        {
            title:"Direct Co-Ownership",
            desc:"Direct registered co-ownership in the property – What you see is what you get",
            img:"1.png"
        },
        {
            title:"Immutable Records",
            desc:"Co-ownership records maintained on an immutable BlockChain Registry (PropChain)",
            img:"2.png"
        },
        {
            title:"Tax Efficient",
            desc:"RealX is at least 25% more Tax Efficient than its peers",
            img:"3.png"
        },
        {
            title:"Zero Compliance",
            desc:"No Compliance burden as we don't hold the asset in an SPV",
            img:"4.png"
        },
        {
            title:"Full Transparency",
            desc:"We are fully transparent in our deals to the extent we know",
            img:"5.png"
        },
        {
            title:"Completely Digital process",
            desc:"Hassle free entry, benefits management (passive) and exit",
            img:"6.png"
        },
        {
            title:"Multiple asset class",
            desc:"Carefully curated properties across Residential, Commercial, Warehousing, Co-living and others",
            img:"5.png"
        },
        {
            title:"Professional Diligence",
            desc:"Property Due Diligence and Commercial Reports from established empanelled 3rd party organisations",
            img:"8.png"
        },
    ]

    return(
        <Stack spacing={4}>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Advantage of RealX</Typography>
                <Typography variant="body3">Besides being one of the most innovative FinTech company in its space globally, RealX has <span style={{fontWeight:600,fontStyle:"italic"}}>investor first</span> approach at its core</Typography>            
            </Stack>
            {
                data.map((e)=>{
                    return(
                        <Stack direction={"row"} spacing={{md:1.5,xs:1}}>
                            <Box sx={{
                                minWidth:60,
                                minHeight:60,
                                maxWidth:60,
                                maxHeight:60,
                                bgcolor:"primary.light",
                                borderRadius:"100%",
                                padding:{md:1.5,xs:2}
                            }}>
                                <Box component={"img"} src={`/assets/images/whyrealx/${e.img}`} />
                            </Box>
                            <Stack spacing={"6px"}>
                                <Typography variant="aboutHeading2">{e.title}</Typography>
                                <Typography variant="body3">{e.desc}</Typography>
                            </Stack>
                        </Stack>
                    )
                })
            }
        </Stack>
    )
}

const Regulations = () =>{
    return(
        <Stack spacing={{md:4,xs:2.5}}>
            <Stack spacing={1.5}>
                {/* <Typography variant="aboutHeading2">RealX was built singularly with investors in mind</Typography> */}
                <Typography variant="body3">
                We have taken care in building RealX within the ambit of the extant laws available to us.  Being built on base established laws of today, the RealX structure is therefore compliant with all applicable laws.</Typography>
                <Typography variant="body3">
                    The legal construct of RealX is unique to it and has taken us a couple of years of work to get there. At the moment there isn't any regulatory framework that we can be licensed under. However, we would be keen to get under active regulatory supervision once a framework is created for us.
                </Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Why don't we fall under regulation?</Typography>
                <Typography variant="body3">
                    Simply put, RealX does not fall under any extant regulations (until new ones are made for us) because of three reasons:
                    </Typography>
                <Typography variant="body3">
                    1. You and only you decide where to invest your money. 
                    There is no blind investing or pooling as a fund or trusting any fund manager here. 
                    Your money, your choice, your decision and its your property.
                </Typography>
                <Typography variant="body3">
                2. The RealX structure creates a direct co-ownership on the property. So the ownership (or rights for it), rest solely in your hands. Everything else is a support function - essentially helping you with property administration.
                </Typography>
                <Typography variant="body3">
                3. All key decisions regarding the ownership and tenancy are taken by you. Just like shareholders in a company, the co-owners of the property are free to keep their stake or sell it at their will as well as vote to decide on all important matters.
                </Typography>
                <Typography variant="body3">
                    Even without direct regulations, we think we can do a whole lot to create a better environment for our investors to invest with confidence at RealX.
                    <br/><br />
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                    Some of the best features of how we do things at RealX are
                    </Typography>
                <Typography variant="body3">
                    <span style={{fontWeight:600}}>1. Separation of Ownership representation and platform functions : </span> 
                    RealX created a separate Registered Trust to carry on the fiduciary functions related to property ownership. The trustee (generally a corporate trustee), will need to ensure they always act as per the Trust clauses and not on what RealX says. This creates a separation of functions, commitments, control  and interest.
                </Typography>
                <Typography variant="body3">
                    <span style={{fontWeight:600}}>2. Maker-Checker in all key functions : </span> 
                    No major action can be unilaterally taken by any entity, be the Trustee, Asset Manager or even RealX. Most of such functions require one entity to initiate an action and another to approve it even before these are placed before investors. 
                    </Typography>
                <Typography variant="body3">
                    <span style={{fontWeight:600}}>3. Each property is designed as a DAO : </span> 
                    All investors in a property become part of a DAO (Distributed Autonomous Organisation) wherein they together can take all key decisions regarding their collective ownership of the property as a whole. Having said that all investors hold their co-ownerships (or co-ownership rights) as freehold assets and they can individually decide to hold or sell them.
                    </Typography>
                    <Typography variant="body3">
                        RealX and its ecosystem, have simply created a standard  framework (of rights, responsibilities and platform rules), for all of you to come together and invest. This helps you invest with confidence knowing very well that the standard framework ensures you know what to expect from other investors who may co-invest with you as well as sets the expected behaviour from everyone else involved in the process. 
                    </Typography>
                    <Typography variant="body3">
                    And we do our best in terms of curation of such properties, oversight of all functional players and intermediaries as well as create efficient mechanisms for fund transfer etc. </Typography>
            </Stack>
            <Stack  spacing={1.5}>
                <Typography variant="aboutHeading2">Will we be regulated?</Typography>
                <Typography variant="body3">
                Regulation is a good thing. It creates more confidence for investors and protects the interests of investors as well as  every other participant in the market. 
                </Typography>
                <Typography variant="body3">
                We are at an early stage of evolution of this product and we believe prudent regulations will follow once the market gains traction enough. We would only be too happy to work with the regulators and governments to support proper regulations for the industry.
                </Typography>
            </Stack>
        </Stack>
    )
}