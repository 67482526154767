import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import { useSelector } from "react-redux";
import { convertToIndianFormat, getTitle } from "../../utils/functions";
import moment from "moment";
import { AmountPaidCommited, DetailButton, TableRow, TableRowImg, TableRowMultilineInfo, TxnRefNumber } from "../../components/miniComponents";
import CustomTable from "../../components/customTable";
import { EmptyTableState } from "../../components/tables";

const CanceledTransactions = () => {
  const user = useSelector(state => state.user.data);
    const [data, setData] = useState([]);

    useEffect(() => {
        getMyInvestments();
    }, [])

    const getMyInvestments = async () => {
        let reqData = {
            investorId: user._id,
            statusArray: ['REJECTED','EXPIRED']
        };

        const res = await transactionServices.getTransactionByLisitngAndUser(reqData);

        if (res && res.success && res.data?.length > 0) {
            
            let tempData = [];
            for (let e of res.data) {

                let temp = {
                    propertyInfo: [
                        getTitle(e.listing) + "-" + e.project.projectName || e.project.projectName,
                        res.url + (e?.listingImages[0]?.docUrl || ""),
                        e.project.projectName,
                        e.listing.propertyCode,
                    ],
                    propertyId: e.listingId,
                    frax: e.noOfFracs,
                    investAmm: convertToIndianFormat(e.amount) || 0,
                    pendingAmount: convertToIndianFormat(e.pendingAmt) || 0,
                    date: moment(e.created_at).format("DD/MM/YYYY") || "12/12/2000",
                    uDate: moment(e.created_at).format("DD/MM/YYYY") || "12/12/2000",
                    percentInvested: `${e.propertyPercent} %`,
                    status: e.status,
                    listingId: e.listingId,
                    projectId: e.projectId,
                    _id: e._id,
                    transactionId: e.transactionId,
                    saleDeed: res.url + (e?.listing?.registrationCertificate[0]?.docUrl || ""),
                    rejectReason: e.rejectReason || 'No reason available',
                };
                tempData.push(temp);
            }

            setData(tempData);
        } else {
            
        }
    };


    const tableHeads = [
        {
            key: "propertyName",
            title: "Property Image"
        },
        {
            key: "propertyInfo",
            title: "Property Info"
        },
        {
            key: "investAmm",
            title: "Amount Commited"
        },
        {
            key: "frax",
            title: "No of FRAX"
        },
        {
            key: "percentInvested",
            title: "% Ownership"
        },
        {
            key: "rejectReason",
            title: "Reject Reason"
        },
    ];



    const tableCells = [<TableRowImg />, <TableRowMultilineInfo />, <TableRow />, <TableRow />, <TableRow />, <TableRow />];
    if (data.length === 0)
        return ( <EmptyTableState showAction={true} title={"No Properties"} subtitle={"You've not made any investments yet."} />)
    // eslint-disable-next-line
    return (
        <>
            {/* <PdfViewer open={openSaleDeed} setOpen={setOpenSaleDeed} url={saleDeedUrl} /> */}
            <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={data} />
        </>
    )
};

export default CanceledTransactions;