import React, { useEffect, useState } from 'react'
import ModalComponent from './modalComponent'
import { Box, Button, Stack, Typography } from '@mui/material'
import Lottie from 'lottie-react';
import warning from "../../animations/warning.json"
import { useNavigate } from 'react-router-dom';

export default function PanCardMessage({open,handlePanMsgClose,handleClose}){
    const navigate = useNavigate();
  return (
    <ModalComponent open={open} handleClose={handlePanMsgClose}>
    <Box sx={{ textAlign: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: "24px 12px" }}>
        <Stack sx={{ mb:2,alignItems: 'center', justifyContent: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: 1, minWidth: { md: '500px' }, mt: 2 }}>
        {
                     
                      <Box sx={{ width: 150, marginTop: "-35px" }}>
                          <Lottie animationData={warning} loop={false} />
                      </Box>

                  }
               <Typography variant='miscTab' sx={{ textAlign:'center'}}>Sorry,we can not service you right now <br/>we will reach out once <br/> we have something for you</Typography>
        </Stack>
        <Button variant='contained' size="small" onClick={()=>{handlePanMsgClose();handleClose()}}>OK</Button>
    </Box>      
     </ModalComponent>
  )
}
