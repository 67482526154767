import React, { useState, useEffect } from "react";
import AreYouSureModal from "../../../../components/modals/areYouSureModal";
import authServices from "../../../../services/authServices";
import CustomIcon from "../../../../components/customIcon";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import userServices from "../../../../services/userServices";
import { useSnackbar } from "notistack";
import hufService from "../../../../services/hufService";
import HufCreateDLTModel from "./HufCreateDLTModel";
const HufDltWallet = ({ hufAccountId ,getHufDetails}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [availableBlockchainAccounts, setAvailableBlockchainAccounts] =
    useState([]);
  const [blockchainAccountModal, setBlockchainAccountModal] = useState(false);
  const [hufAccount, setHufAccount] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const getHufAccount = async () => {
    const res = await userServices.getHufById(hufAccountId);
    if (res && res.success) {
      setHufAccount(res.data[0]);
    } else {
      enqueueSnackbar("Somthing went wrong", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };
  useEffect(() => {
    getHufAccount();
  }, [blockchainAccountModal]);

  const handlePrimary = async () => {
    const res = await hufService.updatePrimaryDLTAccount({
      hufId: hufAccountId,
      accountIndex: currentIndex,
    });
    
    if (res && res.success) {
    getHufAccount();

    }
  };

  const handleRemove = async () => {
    const res = await hufService.removeDLTAccount({
      hufId: hufAccountId,
      accountIndex: currentIndex,
    });
    if (res && res.success) {
    getHufAccount();
      enqueueSnackbar("DLT Account Deleted Successfully!", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "top" },
        autoHideDuration: 3000,
      });
    } else
      enqueueSnackbar(
        "This DLT Account is Holding Some Digital Assets in it. Please Contact RealX Admin for More Information!",
        {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          autoHideDuration: 9000,
        }
      );
  };
  const fetchDltWallet = async () => {
    let dltWallet = await authServices.getDltWalletByUser(hufAccountId);

    let temp = hufAccount?.walletInfo?.publicAddress
      ? [hufAccount.walletInfo.publicAddress]
      : [];

    if (Array.isArray(hufAccount?.walletInfo?.secondaryAccounts)) {
      temp = [...temp, ...hufAccount.walletInfo.secondaryAccounts];
    }

    const mappedEntries = temp.map((address) => {
      const match = dltWallet.data.findIndex(
        (obj) => obj.publicAddress === address
      );
      return match === -1
        ? { publicAddress: address, name: "", created_at: "" }
        : { ...dltWallet.data[match] };
    });

    setAvailableBlockchainAccounts(mappedEntries);
  };

  useEffect(() => {
    fetchDltWallet();
  }, [hufAccount]);

  const handleDLTCreationStatus = async (huf, status) => {
    const res = await hufService.updateDLTCreationStatusHUF({
      hufId: huf,
      status,
    });
    if (res && res.success) {
      getHufAccount();
    }
  };
  

  const copyUrl = (account) => {
    navigator.clipboard.writeText(account);
    enqueueSnackbar("Copied to Clickboard", {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "top" },
      autoHideDuration: 800,
    });
  };

  return (
    <Stack spacing={3} width={"50%"}>
      <AreYouSureModal
        open={primaryOpen}
        setOpen={setPrimaryOpen}
        handleYes={handlePrimary}
        message={"Are You Sure About Updating your Primary DLT Account?"}
        publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress}
        publicAddressMessage={"Your New Primary DLT Account Address"}
      />
      <AreYouSureModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleYes={handleRemove}
        message={"Are You Sure About Permanently Deleting this DLT Account?"}
        note={"This action can not be undo!"}
        publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress}
        publicAddressMessage={"DLT Account Address To Be Removed"}
      />

      <Typography  fontSize={"14px"}>
        Available Blockchain Accounts
      </Typography>

      <Stack
        flexDirection={"column"}
        sx={{
          overflowX: { xs: "auto", sm: "auto", md: "hidden" },
          flexWrap: "wrap",
        }} gap={2}>
        {availableBlockchainAccounts[0] !== undefined &&
        availableBlockchainAccounts.length >= 1 ? (
          availableBlockchainAccounts.map((account, index) => {
            return (
              <>
                <Stack
                  flexDirection={"row"}
                  columnGap={{ xs: 1, sm: 2 }}
                  sx={{
                    width: {
                      lg: "100%",
                      md: "65%",
                      sm: "85%",
                      xs: "min-content",
                    },
                  }}>
                  <Stack
                    flexDirection={"row"}
                    sx={{
                      padding: "15px",
                      borderRadius: "10px",
                      boxShadow: "0px 1px 6px 0px #51515129",
                      width: "100%",
                    }}>
                    <Box sx={{ padding: "2px" }} width={"100%"}>
                      <Box sx={{ display: "flex" }} justifyContent={'space-between'}>
                        {" "}
                        <Typography sx={{fontSize:"16px !important"}}>
                          #{index + 1}. ...{account.publicAddress?.slice(-25)}
                        </Typography>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            copyUrl(account.publicAddress);
                          }}
                          sx={{
                            bgcolor: "#f4f6f7",
                            padding: "7px",
                            borderRadius: "50%",
                            marginLeft: "10px",
                            hight: "20px",
                            width: "30px",
                          }}>
                          <CustomIcon
                            name="copy"
                            sx={{
                              bgcolor: "#FFF",
                              backgroundColor: "#333333",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                        <Box sx={{ marginLeft: "4px" }}>
                          {index == 0 ? (
                            <>
                              <Typography 
                                sx={{
                                  color: "white",
                                  background: "#21ab37",
                                  padding: "2px 15px !important",
                                  fontSize: "12px !important",
                                  border: "1px solid #21ab37",
                                  borderRadius: "10px",
                                }}>
                                Primary Account
                              </Typography>
                            </>
                          ) : (
                            <Stack
                              flexDirection={"row"}
                              alignItems={"center"}
                              columnGap={{ xs: 1, sm: 2 }}
                              sx={{ m: "0 !important" }}>
                              <Button
                                variant="contained"
                                sx={{
                                  p: "4px 15px !important",
                                  fontSize: "12px !important",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setCurrentIndex(index);
                                  setPrimaryOpen(true);
                                }}>
                                Make Primary
                              </Button>
                              <RemoveCircleOutlineIcon
                                sx={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  setCurrentIndex(index);
                                  setDeleteOpen(true);
                                }}
                              />
                            </Stack>
                          )}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingTop: "10px",
                        }}>
                        <Box>
                          <Typography sx={{ fontWeight: "600" ,fontSize:"16px !important"}}>
                            {account?.name?.slice(0, 30)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{fontSize:"16px !important"}}>
                            {account?.created_at
                              ? ` Created On: ${moment(
                                  account?.created_at
                                ).format("DD-MM-YYYY")}`
                              : ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </>
            );
          })
        ) : (
          <>
            <Typography variant="subtitle1">
              You have not made any DLT accounts yet
            </Typography>
          </>
        )}
      </Stack>

      <Stack flexDirection={"row"} gap={1} justifyContent={'center'}>
        <Button
          onClick={() =>
            hufAccount?.walletInfo?.isAllowedToCreateDLT == "APPROVED"
              ? setBlockchainAccountModal(true)
              : handleDLTCreationStatus(hufAccount?._id, "PENDING")
          }
          variant="contained"
          sx={{ width: "max-content", py: "10px !important" }}
          disabled={
            availableBlockchainAccounts?.length > 1 ||
            hufAccount?.walletInfo?.isAllowedToCreateDLT == "PENDING" ||
            ["PENDING", "REJECTED"].includes(hufAccount?.status)
          }>
          {hufAccount?.walletInfo?.isAllowedToCreateDLT == "APPROVED"
            ? "Create a New DLT Account"
            : hufAccount?.walletInfo?.isAllowedToCreateDLT == "PENDING"
            ? "Request Submitted to RealX Admin"
            : "Request to Create a New DLT Account"}
        </Button>

        {hufAccount?.walletInfo?.isAllowedToCreateDLT == "PENDING" && (
          <Button
            variant="contained"
            sx={{ py: "7px !important" }}
            onClick={() => handleDLTCreationStatus(hufAccount?._id, "CREATED")}>
            Cancel Request
          </Button>
        )}
      </Stack>
      {blockchainAccountModal && (
        <HufCreateDLTModel
          open={blockchainAccountModal}
          setOpen={setBlockchainAccountModal}
          huf={hufAccount}
          getHufDetails={()=>getHufDetails()}
        />
      )}
    </Stack>
  );
};

export default HufDltWallet;
