import { server } from "../utils/server";


const getPrevInvestments = (data) =>{
    return server.post("transactions/activeInvestment",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const createTransaction = (data) =>{
    return server.post("transactions/add",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const getMyInvestments = (data) =>{
    return server.post("transactions/get/ByInvestor",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const confirmPayment = (data) =>{
    return server.post("transactions/investorPayment",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const updateTransaction = (data) =>{
    return server.post('transactions/updateTraxn',data)
    .then(res =>{
        return res.data
    })
    .catch(err =>{
        console.log(err)
        return err.response.data
    })
}

const cancelTransaction = (data) =>{
    console.log(data,"iqqiiqiqi");
    
    return server.post('transactions/cancelTraxn',data)
    .then(res =>{
        return res.data
    })
    .catch(err =>{
        console.log(err)
        return err.response.data
    })
}
const confirmSecondPartPayment = (data) =>{
    return server.post("transactions/investorPayment/secondpart",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const checkFracs = (data) =>{
    return server.post("transactions/checkavailability",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const createOrder = (data) =>{
    return server.post("transactions/createorder",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}


const createOrderRemAmt = (data) =>{
    return server.post("transactions/createorder/pendingAmt",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const getPortfolioDetails = async(data)=>{
    return await server.post(`/transactions/portfolio`,data).then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
        
    })
}

const getTransactionByLisitngAndUser = async (data) => {
//   data = {   
//     investorId: "Optional",
//     listingId: "optional",
//     rco: "Boolean optional",
//     statusArray:"Array of status ["CREATED"] optional",
//     pendingAmt: "Optional boolean; false to include transactions with pending amounts, true to exclude them."
//   };
 
const response = await server
    .post("/transactions/transactionOfuserBylisting", data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("-------getTransactionByLisitngAndUser----------------", err);
    });
  return response;
};

const transactionServices = {
    getPrevInvestments,
    createTransaction,
    getMyInvestments,
    confirmSecondPartPayment,
    confirmPayment,
    checkFracs,
    createOrder,
    createOrderRemAmt,
    cancelTransaction,
    updateTransaction,
    getPortfolioDetails,
    getTransactionByLisitngAndUser
}

export default transactionServices;