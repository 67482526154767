import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import { convertToIndianFormat, getTitle } from "../../utils/functions";
import moment from "moment";
import { AmountPaidCommited, DetailButton, TableRowImg, TableRowMultilineInfo, TxnRefId, TxnRefNumber } from "../miniComponents";
import { Box, Button, TableRow } from "@mui/material";
import CustomTable from "../customTable";
import { useNavigate } from "react-router-dom";

const AllTransactionByListing = ({ data }) => {
  const [transactionData, setTransactionData] = useState();
  const navigate = useNavigate();

  const getAllTransactrions = async () => {
    const res = await transactionServices.getTransactionByLisitngAndUser(data);
    const tempData = [];
    if (res && res.success && res.data?.length > 0) {
      res.data.map((data) => {
        data.payments.map((e) => {
          tempData.push({
            _id: e.paymentNo,
            rpf: (data?.rpf || e?.amount / e?.noOfFracs)?.toFixed(2),
            amount: convertToIndianFormat(e.amountByAdmin),
            frax: data?.noOfFracs || 0,
            updated_at: moment.unix(e?.dateoftrans).format("DD/MM/YYYY"),
            listingId: e.listingId,
            refId: e?.chequeno,
            transactionId: data.transactionId
          });
        });
      })

    }
    setTransactionData(tempData)
  };

  useEffect(() => {
    getAllTransactrions();
  }, []);



  const tableHeads = [

    {
      key: "amount",
      title: "Invested Amount"
    },
    {
      key: "refId",
      title: "Reference ID"
    },
    {
      key: "frax",
      title: "No of\nFRAX"
    },
    {
      key: "rpf",
      title: "Rate/\nFRAX"
    },

    {
      key: "updated_at",
      title: "Payment Date"
    },
    {
      key: "transactionId",
      title: "Transaction ID",
    },
    // {
    //   key: "click",
    //   title: "Details",
    //   onClick: () => {
    //     navigate(`/dashboard/property/${data.listingId}`, { state: { id: data.listingId } })
    //   }
    // },
  ];
  const tableCells = [<TableRow />, <TxnRefId />, <TableRow />, <TableRow />, <TableRow />, <TxnRefNumber />];
  return (
  <Box >
    <Button variant="outlined" onClick={()=>navigate(`/dashboard/property/${data.listingId}`, { state: { id: data.listingId } })} sx={{float:'right', padding:'10px', marginBottom:'10px'}}>
      Show property details
    </Button>
  <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={transactionData} />
  </Box>
  )

};

export default AllTransactionByListing;
