import store from "../redux/store";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { clearUser } from "../redux/reducers/userSlice";


export const convertToIndianFormat = (x, isAmount = true) => {
    if (x) {
        if (isAmount) {
            return (
                <span style={{ whiteSpace: "nowrap" }}>
                    <span style={{ fontFamily: "Roboto, sans-serif" }}>₹ </span>
                    {x.toLocaleString('en-IN')}
                </span>
            );
        } else {
            return x.toLocaleString('en-IN');
        }
    }

    return x;
}


export const isInWatchlist = (id) => {
    const watchlist = store.getState().user.watchlist;
    return watchlist.find((e) => e._id === id);
}

export const getTitle = (propertyData) => {
    if (propertyData?.propertyType === "Residential") return `${propertyData?.basicInfo?.flatNo}, Wing-${propertyData?.basicInfo?.wing}`
    if (propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail") return `${propertyData?.basicInfo?.unitNo}, Wing-${propertyData?.basicInfo?.wing}`
    if (propertyData?.propertyType === "Land") return `Plot No. ${propertyData?.basicInfo?.plotNo}`
    if (propertyData?.propertyType === "Warehouse") return `Warehouse No. ${propertyData?.basicInfo?.warehouseNo}`
    return "";
}

export const checkLogin = () => {
    const token = sessionStorage.getItem('access');
    if (token) {
        const decodedToken = jwt_decode(token);
        // console.log(moment.unix(decodedToken.exp).isBefore(moment.now()))
        if (moment.unix(decodedToken.exp).isBefore(moment.now())) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        return false;
    }
}

export const loggout = () => {
    sessionStorage.clear();
    store.dispatch(clearUser());
}

export const getDocName = (docname) => {
    if (docname) {
        return (docname.split('圓')[1])?.split('.')[0] || ""
    }
    else {
        return "";
    }
}

export function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24;

    return `${padTo2Digits(hours)}h : ${padTo2Digits(minutes)}m : ${padTo2Digits(seconds)}s`;
}

export const calculatePercent = (partial, total) => {
    // console.log((100*target)/totalCost,"percentage")
    return (100 * partial) / total;
}

export const calcTarget = (noOfFrax, noOfRemFrax, ratePerFrax) => {
    const buyedFrax = noOfFrax - noOfRemFrax;
    const totalInvestmentComplete = Math.ceil(buyedFrax * ratePerFrax);
    return totalInvestmentComplete || 0;
}